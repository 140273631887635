<template>
<div>
    <div class=" bg-dark p-4 mt-3 full-width" >
        <div class="" >
            <div class="row">
                <div class="col-md-8 col-sm-6 col-xs-12  ">
                    <b-button  v-if="firstButtonShow == 'true'" variant="primary" class="font-weight-bold ml-5 "
                               style="border-radius: 0px !important;">{{firstButton}}</b-button>
                    <b-button  v-if="firstButtonShow == 'dark'" variant="dark"
                               class="font-weight-bold custom ml-5 "
                               style="border-radius: 0px !important; background-color: black" @click="$router.go( -1 )">Back</b-button>
                </div>


                <div class="col-md-4 col-sm-6 col-xs-12  " v-if="secondButtonShow == 'true'">
                    <div>
                        <b-button   variant="primary" class="font-weight-bold ml-5"
                                  style="border-radius: 0px !important;"
                                    :to="secondButtonLink">{{secondButton}}</b-button>

                        <b-button  variant="primary" class="font-weight-bold ml-5 "
                                  style="border-radius: 0px !important;"
                                   :to="thirdButtonLink">{{thirdButton}}</b-button>
                    </div>

                </div>
                <div class="col-md-4 col-sm-6 col-xs-12 text-center pl-5 " v-else>
                    <div v-if="thirdButtonShow !='false' ">
                        <b-button  variant="primary" class="font-weight-bold "
                                   style="border-radius: 0px !important;">{{thirdButton}}</b-button>
                    </div>
                    <div v-else class="p-3">

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "footer",
    props: ['firstButton','firstButtonShow','firstButtonLink',
        'secondButton','secondButtonShow','secondButtonLink',
        'thirdButton','thirdButtonShow','thirdButtonLink'],

    data(){
        return{

        }
    }
}
</script>

<style scoped>
.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
.custom {
    width: 98px !important;
}
</style>
