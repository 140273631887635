<template>
<div>
    <headerDesign name="Product Management"
                  logo="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                  button-show="true"
                  button-name="Client Management"
                  class="mb-4"></headerDesign>
    <div class="container pt-5">
        <form>
            <div class="form-row">
                <div class="col">
                    <input type="text" class="form-control" placeholder="First name">
                </div>
                <div class="col">
                    <input type="text" class="form-control" placeholder="Last name">
                </div>
            </div>
            <div class="form-row pt-3">
                <div class="col">
                    <input type="text" class="form-control" placeholder="First name">
                </div>
                <div class="col">
                    <input type="text" class="form-control" placeholder="Last name">
                </div>
            </div>
            <div class="form-row pt-3">
                <div class="col">
                    <input type="text" class="form-control" placeholder="First name">
                </div>
                <div class="col">
                    <input type="text" class="form-control" placeholder="Last name">
                </div>
            </div>

        </form>

        <div class="row pt-5 pb-4">
            <div class="col-12 text-center">
                <h3 class="font-weight-normal"> Products</h3>
            </div>

        </div>

        <div class=" row">
            <div class="col-4 text-center">
                <b-card
                    title="AV"
                    tag="article"
                    class="mb-2 cardStyle"
                >
                    <b-card-text>
                       Ad Verifier
                    </b-card-text>
                </b-card>
                <div class="row">
                    <div class="col-12 text-center">
                        <b-button href="#" class="btn  custom" variant="dark"
                                  style="border-radius: 0px !important;">Set Up</b-button>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button href="#" variant="primary"  class="btn custom"
                                  style="border-radius: 0px !important;">Add Ons</b-button>

                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button href="#" class="text-danger btn custom"
                                  style="border-radius: 0px !important;"
                                  variant="outline-light">Delete</b-button>
                    </div>

                </div>




            </div>
            <div class="col-4 text-center">
                <b-card
                    title="CC"
                    class="mb-2 cardStyle"
                >
                    <b-card-text>
                       Content Catcher
                    </b-card-text>


                </b-card>
                <div class="row">
                    <div class="col-12 text-center">
                        <b-button href="#" class="btn  custom" variant="dark"
                                  style="border-radius: 0px !important;">Set Up</b-button>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button href="#" variant="primary"  class="btn custom"
                                  style="border-radius: 0px !important;">Add Ons</b-button>

                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button href="#" class="text-danger btn custom"
                                  style="border-radius: 0px !important;"
                                  variant="outline-light">Delete</b-button>
                    </div>

                </div>
            </div>
            <div class="col-4 text-center">
                <b-card
                    title="EV"
                    tag="article"
                    class="mb-2 cardStyle"
                >
                    <b-card-text>
                        Email Varifier
                    </b-card-text>

                </b-card>

                <div class="row">
                    <div class="col-12 text-center">
                        <b-button href="#" class="btn  custom" variant="dark"
                                  style="border-radius: 0px !important;">Set Up</b-button>
                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button href="#" variant="primary"  class="btn custom"
                                  style="border-radius: 0px !important;">Add Ons</b-button>

                    </div>

                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <b-button href="#" class="text-danger btn custom"
                                  style="border-radius: 0px !important;"
                                  variant="outline-light">Delete</b-button>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <footerDesign  first-button="Add a Product" first-button-show="false"  second-button="Manage Domains"
                  second-button-show="true" third-button="Manage Products" class="mt-4">

    </footerDesign>
</div>
</template>

<script>

import footerDesign from "@/components/style_Component/footerDesign";
import headerDesign from "@/components/style_Component/headerDesign";

export default {
    name: "product.Management",
    components: {

        footerDesign,
        headerDesign
    },
    data(){
        return{

        }
    },
    created() {
        this.$root.preloader = false;
    }
}
</script>

<style scoped>
.custom {
    width: 98px !important;
}
.cardStyle{
    max-width: 30rem; height: 14.5rem; padding: 30px; font-size: 25px
}
.card-title {
  font-size:  5rem !important;
}
</style>
