<template>
    <div>
        <div class=" bg-dark p-3 mt-3 full-width" >

                <div class="row">
                    <div class="col-md-1 col-sm-1 col-xs-12">
                        <b-img :src="logo" class="w-50 ml-5"></b-img>
                    </div>

                    <div class="col-md-6 col-sm-4 col-xs-12">
                        <h3 class="text-white mt-3">{{name}}</h3>
                    </div>

                    <div class="col-md-4 col-sm-6 col-xs-12">
                        <div class="d-flex justify-content-end pt-3">
                            <b-button  v-if="ButtonShow == 'true'" variant="primary" class="font-weight-bold mr-4"
                                       style="border-radius: 0px !important;">{{ButtonName}}</b-button>
                        </div>

                    </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "header",
    props: ['logo','name','ButtonName',
        'ButtonLink', 'ButtonShow'],
}
</script>

<style scoped>
.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
</style>
